import React, { useEffect, useState } from 'react';
import MemorizationGame from '../memgame/MemorizationGame';

interface VerseProps {
    reference: string;
    subtitle: string;
    verseText: string;
    audioUrl: string | null;
}

function Verse({ reference, subtitle, verseText, audioUrl }: VerseProps) {
    const [showGame, setShowGame] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [isAudioLoaded, setIsAudioLoaded] = useState(false);
    const [wasListenButtonClicked, setWasListenButtonClicked] = useState(false);
    const [audioLoadError, setAudioLoadError] = useState(false);

    useEffect(() => {
        if (audioUrl) {
            const newAudio = new Audio(audioUrl);
            newAudio.addEventListener('canplaythrough', () => setIsAudioLoaded(true));
            newAudio.addEventListener('error', () => setAudioLoadError(true));
            setAudio(newAudio);
        }
    }, [audioUrl]);

    const handleAudioButtonClick = async () => {
        setWasListenButtonClicked(true);
        setIsLoading(true);

        if (audio && isAudioLoaded) {
            try {
                await audio.play();
            } catch (error) {
                console.error('Error playing audio:', error);
                setAudioLoadError(true);
            } finally {
                setIsLoading(false);
            }
        } else {
            const checkAudioLoaded = setInterval(() => {
                if (isAudioLoaded) {
                    clearInterval(checkAudioLoaded);
                    audio?.play().catch(error => {
                        console.error('Error playing audio:', error);
                        setAudioLoadError(true);
                    });
                    setIsLoading(false);
                }
            }, 100);

            setTimeout(() => {
                clearInterval(checkAudioLoaded);
                if (!isAudioLoaded) {
                    setAudioLoadError(true);
                    setIsLoading(false);
                }
            }, 10000);
        }
    };

    const getAudioEmoji = () => {
        if (audioLoadError) return '❌';
        if (wasListenButtonClicked && (isLoading || !isAudioLoaded)) return <LoadingSpinner />;
        return '📣👂';
    };

    const handleQuitGame = () => {
      setShowGame(false);
    };

    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <h4>{reference}</h4>
                </div>
                <div className="col-6">
                    <p title="read me!" className="text-end">{subtitle}</p>
                </div>
            </div>
            <div className="App">
                {!showGame && (
                    <div>
                        <p>{verseText}</p>
                        <button onClick={handleAudioButtonClick} className="btn btn-secondary mx-1" disabled={isLoading || audioLoadError}>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Listen <span style={{ marginLeft: '5px' }}>{getAudioEmoji()}</span>
                            </span>
                        </button>
                        <button onClick={() => setShowGame(true)} className="btn btn-secondary mx-1">
                            Practice 😀💡
                        </button>
                    </div>
                )}
                {showGame && <MemorizationGame text={verseText} onQuit={handleQuitGame} />}
            </div>
        </div>
    );
}

const LoadingSpinner = () => (
    <div
        className="spinner"
        style={{
            width: '20px',
            height: '20px',
            border: '2px solid #f3f3f3',
            borderTop: '2px solid #3498db',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
            marginLeft: '5px',
            display: 'inline-block',
        }}
    ></div>
);

export default Verse;