import React from 'react';

function Video(props: any) {
    return (
        <div className="row-sm">
            <iframe className="d-sm-none" style={{ aspectRatio: '16 / 9', width: '100%' }} width="560" height="315" src={props.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            <iframe className="d-none d-sm-block" style={{ aspectRatio: '16 / 9' }} width="560" height="315" src={props.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
    );
}

export default Video;