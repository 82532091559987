import React, { useEffect, useState } from 'react';
import Book from './Book';
import { Book as BookType } from '../types/Book'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useAppState } from '../App';

const BookLayout = () => {
  const { id } = useParams();
  const { state } = useAppState();

  const [book, setBook] = useState<BookType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        //const response = await axios.get<BookType>(`https://api.buyck.dev/getBook?book=${id}`);
        
        const realId = parseInt(id || '0') - 1;

        setBook(state.books[realId]);
      } catch (error) {
        console.error('Error fetching data from API:', error);
      }
    };
    fetchData();
  }, [id]);


  return (
    <div>
      <div className="row">
        <div className="col-sm-2">
        </div>
        <div className="col-sm-8">
          <Breadcrumb className="breadcrumb2 px-4 p-md-0">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Book {id}</Breadcrumb.Item>
          </Breadcrumb>
          {book && <Book book={book} />}
        </div>
        <div className="col-sm-2">
        </div>
      </div>
    </div>
  );
};

export default BookLayout;
