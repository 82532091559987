import { useRef, useState } from "react";
import Confetti from "react-confetti";

const MemorizationGameConfetti = ({showConfetti, penalty}: {showConfetti: boolean, penalty: number}) => {
    const [numConfettiPieces, setNumConfettiPieces] = useState(20);

    const getRandomEmoji = () => {
        const emojis = ['🎉', '🥳', '🎈', '✨'];
        return emojis[Math.floor(Math.random() * emojis.length)];
    }
    // const getRandomChristmasEmoji = () => {
    //     const emojis = ['🎄', '❄', '👶', '⛄', '🔴', '🟢'];
    //     return emojis[Math.floor(Math.random() * emojis.length)];
    // }
    const getConfettiCount = () => {
        if (penalty === 0) {
            return 20;
        }
        if (penalty >= 15) {
            return 10;
        }
    };
    const createEmojiConfetti = (emoji: string) => {
        return (context: CanvasRenderingContext2D) => {
          context.font = '32px serif';
          context.textBaseline = 'middle';
          context.textAlign = 'center';
          context.fillText(emoji, 0, 0);
        };
      };
    
      return (
        <div>
          <Confetti
            ref={useRef<HTMLCanvasElement>(null)}
            initialVelocityY={-5}
            numberOfPieces={showConfetti ? getConfettiCount() : 0}
            wind={0}
            drawShape={createEmojiConfetti('✨')}
          />
          <Confetti
            ref={useRef<HTMLCanvasElement>(null)}
            initialVelocityY={-5}
            numberOfPieces={(showConfetti && (penalty <= 15)) ? getConfettiCount()  : 0}
            wind={0}
            drawShape={createEmojiConfetti('🎈')}
          />
          <Confetti
            ref={useRef<HTMLCanvasElement>(null)}
            initialVelocityY={-5}
            numberOfPieces={(showConfetti && (penalty === 0)) ? getConfettiCount()  : 0}
            wind={0}
            drawShape={createEmojiConfetti('😀')}
          />
        </div>
      );    
}

export default MemorizationGameConfetti