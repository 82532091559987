import React from 'react';
import { Book as BookType } from '../types/Book'
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';

function Book({ book }: { book: BookType }) {
  return (
    <div className="container p-4 p-md-0">
      <h1>{book.name}</h1>
      <p>{book.introText}</p>
      <div className="pt-3">
        <ListGroup>
          {book.mileMarkers.map((mileMarker, index) => (
            <ListGroup.Item action href={`/books/${book.number}/milemarkers/${mileMarker.number}`}>
              <span key={index}>
                <Link to={`/books/${book.number}/milemarkers/${mileMarker.number}`}>Mile Marker #{mileMarker.number} - {mileMarker.introTitle}</Link></span>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    </div>
  );
};

export default Book;
