import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const MyDatePicker: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [bookNumber, setBookNumber] = useState<number>(0);
  const [mileMarkerNumbers, setMileMarkerNumbers] = useState<number[]>([]);

  const handleMileMarkerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = event.target.value.split(',').map(num => parseInt(num, 10));
    setMileMarkerNumbers(values);
  };

  const handleSubmit = () => {
    const data = {
      date: startDate.toISOString(),
      bookNumber,
      mileMarkerNumbers,
    };
    console.log(data);
    // Handle the data as per your requirement
  };

  return (
    <div>
      <DatePicker
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        showTimeSelect
        dateFormat="Pp"
      />
      <input
        type="number"
        value={bookNumber}
        onChange={(e) => setBookNumber(parseInt(e.target.value, 10))}
      />
      <input
        type="text"
        value={mileMarkerNumbers.join(',')}
        onChange={handleMileMarkerChange}
      />
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default MyDatePicker;
