import axios from "axios";
import React, { useState } from 'react';
import '../App.css';

const MemorizationGameOption = ({props}: {props: MemorizationGameOptionProps}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleAudioButtonClick = async (text: string) => {
        try {
          // Call the function to handle the API request and audio playback

          setIsLoading(true);
          await playTextToSpeech(text);
        } catch (error) {
          console.error('Error playing text to speech:', error);
        } finally {
          setIsLoading(false);
        }
      };

      return (
        <div>
            {/* <button 
                className="btn btn-sm btn-secondary audio-button" 
                style={{ 
                    fontSize: '1.5rem', 
                    marginLeft: "10px",
                    width: '40px', 
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0
                }} 
                onClick={() => handleAudioButtonClick(props.value)}
                disabled={isLoading}
            >
                {isLoading ? <LoadingSpinner /> : '📣'}
            </button> */}
        </div>
    );
}

const LoadingSpinner = () => (
  <div className="spinner" style={{
      width: '20px',
      height: '20px',
      border: '2px solid #f3f3f3',
      borderTop: '2px solid #3498db',
      borderRadius: '50%',
      animation: 'spin 1s linear infinite'
  }}></div>
);

type MemorizationGameOptionProps = {
    //prependText: string;
    isCorrectOption: boolean;
    value: string;
}

export default MemorizationGameOption;

const fetchApiKey = async () => {
  const response = await fetch('https://api.adventurecrewonline.com/get-secret');
  const data = await response.json();
  return data.apiKey;
}


  const playTextToSpeech = async (text: string) => {
    try {
        // Set the API key for ElevenLabs API
        const apiKey = await fetchApiKey();

        // ID of voice to be used for speech
        const voiceId = 'TX3LPaxmHKxFdv7VOQHJ';

        // API request options
        // TODO see if you can change the speed of the voice
        const apiRequestOptions: any = {
            method: 'POST',
            url: `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`,
            headers: {
            accept: 'audio/mpeg',
            'content-type': 'application/json',
            'xi-api-key': apiKey,
            },
            data: {
            text: text,
            },
            responseType: 'arraybuffer', // To receive binary data in response
        };

        // Sending the API request and waiting for response
        const apiResponse = await axios.request(apiRequestOptions);
  
        
        const audioBlob = new Blob([apiResponse.data], { type: 'audio/mpeg' });
        playAudioBlob(audioBlob);
    } catch (error) {
      console.error('Error in playTextToSpeech:', error);
      throw error;
    }
  };

  const playAudioBlob = (blob: Blob) => {
    const audioUrl = URL.createObjectURL(blob);
    const audio = new Audio(audioUrl);
    audio.play();
  };