import React from 'react';
import { MileMarker } from '../types/MileMarker';
import Video from '../mile-marker/Video';

function BookMileMarker({ mileMarker, index }: { mileMarker: MileMarker, index: number }) {
    return (
        <div className="container p-4 p-md-0">
            <div className="row">
                <div className="col-sm-4">
                    <h2 key={index}>{mileMarker.introTitle}</h2>
                    <p key={index}>{mileMarker.introText}</p>
                </div>
                <div className="col-sm-4">
                    <h2>Mile Marker #{mileMarker.number}</h2>
                    <h4 key={index}>{mileMarker.mileMarkerReference}</h4>
                    <p key={index}>{mileMarker.mileMarkerText}</p>
                    {/* <p key={index}>{mileMarker.magnifyingGlassTitle}</p>
                    <p key={index}>{mileMarker.magnifyingGlassText}</p> */}
                    <h2>Extra Mile</h2>
                    <h4 key={index}>{mileMarker.extraMileReference}</h4>
                    <p key={index}>{mileMarker.extraMileText}</p>
                </div>
                <div className="col-sm-4">
                    <h2>Music Video</h2>
                    <Video video={mileMarker.video} />
                </div>
            </div>
        </div>
    );
};

export default BookMileMarker;
