import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useAppState } from '../App';

const BookLayout = () => {
    const { state } = useAppState();

    return (
        <div>
            <div className="row">
                <div className="col-sm-2">
                </div>
                <div className="col-sm-8">
                    <Breadcrumb className="breadcrumb2 px-4 p-md-0">
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Administration</Breadcrumb.Item>
                    </Breadcrumb>
                    <table>
                        <thead>
                            <tr>
                                <th>Book Number</th>
                                <th>MileMarker Number</th>
                                <th>YouTube Embed URL</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                state.books.map(book =>
                                    book.mileMarkers.map(mileMarker => (
                                        <tr key={`${book.number}-${mileMarker.number}`}>
                                            <td>{book.number}</td>
                                            <td>{mileMarker.number}</td>
                                            <td>{mileMarker.video}</td>
                                            <td>coming soon</td>
                                        </tr>
                                    ))
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-sm-2">
                </div>
            </div>
        </div>
    );
};

export default BookLayout;
