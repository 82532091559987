import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import MemorizationGameConfetti from "./MemorizationGameConfetti";
import MemorizationGameOption from "./MemorizationGameOption";
import '../App.css';

declare global {
    interface Window {
        confettiTimeout: NodeJS.Timeout | undefined;
    }
}

type MemorizationGameProps = {
    text: string;
    onQuit: () => void;
};
  
const MemorizationGame = ({ text, onQuit }: MemorizationGameProps) => {
    const words = useMemo(() => text.split(" "), [text]);
    const [progress, setProgress] = useState(0);
    const [progressBarProgress, setProgressBarProgress] = useState(0);
    const [options, setOptions] = useState<MemorizationGameOptionProps[]>([]);
    const [animationText, setAnimationText] = useState("");
    const [showConfetti, setShowConfetti] = useState(false);
    const [depletion, setDepletion] = useState(0);
    const [isGameFinished, setIsGameFinished] = useState(false);
    const [accumulatedConfettiScale, setAccumulatedConfettiScale] = useState(1);
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [isConfettiClickable, setIsConfettiClickable] = useState(false);
    const [penalty, setPenalty] = useState(0);

    const progressPercentage = Math.round((progress / words.length) * 100);

    useEffect(() => {
        let depleteInterval: NodeJS.Timeout;
        if (showConfetti) {
            depleteInterval = setInterval(() => {
                setDepletion(prev => {
                    if (prev >= 100 - penalty) {
                        setTimeout(() => {
                            clearInterval(depleteInterval);
                            setShowConfetti(false);
                            setIsGameFinished(true);
                        }, 300);
                        return 100 - penalty;
                    }
                    return prev + 1;
                });
            }, penalty === 0? 100 : 30);
        }
        return () => {
            if (depleteInterval) {
                clearInterval(depleteInterval);
            }
        };
    }, [showConfetti, penalty]);

    useEffect(() => {
        if (progress < words.length){
            const newOptions = generateOptions(words, progress);
            setOptions(newOptions);
        }
    }, [progress, words])

    useEffect(() => { // for timeout that determines when to pump stored confetti into the progress bar
        return () => {
            if (window.confettiTimeout) {
                clearTimeout(window.confettiTimeout);
            }
        };
    }, []);

    const handleGameCompletion = () => {
        setIsConfettiClickable(true);
    };

    const handleRestartGame = () => {
        setProgress(0);
        setProgressBarProgress(0);
        setDepletion(0);
        setIsGameFinished(false);
        setShowConfetti(false);
        setIsGameComplete(false);
        setIsConfettiClickable(false);
        setPenalty(0);
    };

    const handleConfettiClick = () => {
        if (isConfettiClickable) {
            setShowConfetti(true);
            setIsConfettiClickable(false);
        }
    };

    const handleOptionClick = (option: MemorizationGameOptionProps, index: number) => {
        if (option.isCorrectOption) {
            setAnimationText(option.value);
            const buttonElement = optionRefs[index].current!;
            const startPosition = buttonElement.getBoundingClientRect();
            const endPosition = displayedVerseRef.current!.getBoundingClientRect();
    
            const progressMade = option.value.split(" ").length;
            const newProgress = progress + progressMade;
            
            animateCorrectChoice(startPosition, endPosition, progressMade, newProgress, buttonElement);
            
            // Check if this is the final correct option
            if (newProgress === words.length) {
                setIsGameComplete(true);
            }
        }
        else {
            setPenalty(penalty + 10);
        }
    }

    const createOptionRefs = (length: number) => {
        return Array.from({ length }).map(() => React.createRef<HTMLDivElement>());
    };

    const optionRefs = createOptionRefs(options.length);
    const displayedVerseRef = React.createRef<HTMLParagraphElement>();

    const [textAnimationStyle, setTextAnimationStyle] = useSpring(() => ({
        left: 0,
        top: 0,
        opacity: 1,
        scale: 1,
    }));

    const [confettiAnimationStyle, setConfettiAnimationStyle] = useSpring(() => ({
        scale: 1
    }))

    const animateCorrectChoice = (start: DOMRect, end: DOMRect, progressMade: number, newProgress: number, buttonElement: HTMLElement) => {
        animateTextJump(start, end, buttonElement).then(() => {
            setProgress(newProgress);
            animateProgress(1.1 ** progressMade, newProgress);
        });
    }

    const animateTextJump = (start: DOMRect, end: DOMRect, buttonElement: HTMLElement): Promise<void> => {
        return new Promise((resolve) => {
            const buttonStyle = window.getComputedStyle(buttonElement);
            const buttonPaddingLeft = parseFloat(buttonStyle.paddingLeft);
            const buttonPaddingRight = parseFloat(buttonStyle.paddingRight);
    
            const startX = start.left + (start.width - buttonPaddingLeft - buttonPaddingRight) / 2;
            const startY = start.top + start.height / 2;
    
            const midPoint = {
                left: (startX + end.left) / 2,
                top: (startY + end.top) / 2,
            };
            const secondPoint = {
                left: startX + 0.8 * (end.left - startX),
                top: startY + 0.8 * (end.top - startY),
            }
            setTextAnimationStyle({
                from: { left: startX, top: startY, opacity: 1, scale: 1 },
                to: [
                    { left: midPoint.left, top: midPoint.top, opacity: 1, scale: 1.15, config: {duration:600} },
                    { left: secondPoint.left, top: secondPoint.top, opacity: 1, scale: 1.1, config: {duration:200} },
                    { left: end.left, top: end.top, opacity: 1, scale: 1 },
                    { opacity: 0, config: { duration: 0 } }
                ],
                config: { duration: 250 },
                onRest: () => {
                    resolve();
                },
            });
        });
    };

    const animateProgress = (confettiScale: number, newProgress: number) => {
        growConfetti(confettiScale);
        setAccumulatedConfettiScale(prev => Math.min(2.5, prev + Math.log(confettiScale))); // 2.5 is the max scale
    
        // Clear any existing timeout
        if (window.confettiTimeout) {
            clearTimeout(window.confettiTimeout);
        }
    
        // Set a new timeout
        window.confettiTimeout = setTimeout(() => {
            animateProgressBar(newProgress);
            shrinkConfetti();
        }, 1000);
    };

    const growConfetti = (confettiScale: number) => {
        setConfettiAnimationStyle({
            to: { scale: accumulatedConfettiScale * confettiScale },
            config: { duration: 300 }
        });
    };

    const shrinkConfetti = () => {
        setConfettiAnimationStyle({
            to: { scale: 1 },
            config: { duration: 300 }
        });
        setAccumulatedConfettiScale(1);
    };

    const animateProgressBar = (newProgress: number) => {
        setProgressBarProgress(newProgress);
        
        // Check if this is the final progress update
        if (newProgress === words.length) {
            // Use setTimeout to ensure the progress bar animation completes before showing confetti
            setTimeout(() => {
                handleGameCompletion();
            }, 300); // Adjust this delay if needed to match your progress bar animation duration
        }
    }

    function getProgressBarClassName(): string | undefined {
        const bgColor = penalty === 0 ? "bg-success" : ""
        const finishBgColor = penalty === 0? "bg-warning" : ""
        if (isConfettiClickable || showConfetti){
            return `progress-bar progress-bar-striped progress-bar-animated ${finishBgColor}`
        }
        else if (penalty >= 0) {
            return `progress-bar progress-bar-striped ${bgColor}`
        }
    }

    const getProgressBarWidth = useCallback((): number => {
        const progressPercentage = Math.round((progressBarProgress / words.length) * 100);
        
        if (showConfetti) {
            return Math.max(0, 100 - penalty - depletion);
        } else if (!showConfetti && penalty > progressPercentage) {
            return 0;
        } else {
            return (progressPercentage - penalty);
        }
    }, [progressBarProgress, words.length, showConfetti, penalty, depletion]);

    function handleQuitButtonClick(): void {
        handleRestartGame();
        onQuit();
    }

    return (
        <div>
            {!isGameComplete && (progressPercentage > penalty || penalty === 0) ?
                <div>
                    <p>{words.slice(0, progress).join(" ").concat('\u00A0')}<span ref={displayedVerseRef}></span></p>
                </div>
                :
                <div>
                    <p>{words.join(" ")}</p>
                </div>
            }
            <MemorizationGameConfetti showConfetti={showConfetti} penalty={penalty}/>
            <div className="row">
                <div className="row">
                    <div className="col d-flex align-items-center">
                        {!isGameFinished ? (
                            <div className="d-flex align-items-center" style={{ width: '100%' }}>
                                <span
                                    className="emoji mr-2"
                                    style={{
                                        fontSize: '2rem',
                                        flexShrink: 0,
                                        cursor: isConfettiClickable ? 'pointer' : 'default'
                                    }}
                                    onClick={handleConfettiClick}
                                >
                                    <animated.div
                                        style={{
                                            ...confettiAnimationStyle,
                                            pointerEvents: isConfettiClickable ? 'auto' : 'none',
                                            zIndex: 1000,
                                            transform: confettiAnimationStyle.scale.to(scale => `scale(${scale})`)
                                        }}
                                    >
                                        🎉
                                    </animated.div>
                                </span>
                                <div className="progress" style={{ height: '18px', flex: 1 }}>
                                    <div
                                        className={getProgressBarClassName()}
                                        role="progressbar"
                                        style={{
                                            width: `${getProgressBarWidth()}%`
                                        }}
                                    ></div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {penalty === 0 ? (
                                        <div>
                                            <p>Great job! You did perfectly!</p>
                                        </div>
                                ) : (
                                    <div>
                                        <p>Nice job! Practice without a mistake for more confetti fun!</p>
                                    </div>
                                )}
                                <button className="btn mx-1" onClick={handleRestartGame}>
                                    Practice again
                                </button>
                                <button className="btn btn-secondary mx-1" onClick={() => handleQuitButtonClick()}>
                                    Quit
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div>
            <div className="options-container">
                    {!isGameComplete && (progressPercentage > penalty || penalty === 0) ? (
                        options.map((option, index) => (
                            <div className="option-wrapper" key={index}>
                                <div
                                style={{
                                    minWidth: "10rem",
                                    minHeight: "3rem",
                                    maxWidth: "10rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center"
                                  }}
                                    className="btn btn-sm"
                                    ref={optionRefs[index]}
                                    onClick={() => handleOptionClick(option, index)}>
                                    {option.value}
                                </div>
                                <MemorizationGameOption props={option} />
                            </div>
                        ))
                    ) : progressPercentage > penalty || isConfettiClickable ? (
                        <div>
                            {isConfettiClickable && (<button className="btn btn-secondary" onClick={handleConfettiClick}>
                            Fire the confetti cannon!!! 🎉🥳🎈
                        </button>)}
                        </div>
                    ) : (
                                <div>
                                    <div>
                                        <p>Oops! Try again!</p>
                                    </div>
                                    <button className="btn mx-1" onClick={handleRestartGame}>
                                        Practice again
                                    </button>
                                    <button className="btn btn-secondary mx-1" onClick={() => handleQuitButtonClick()}>
                                        Quit
                                    </button>
                                </div>
                    )}
                </div>
                <div>
                    {!isGameComplete && (progressPercentage > penalty || penalty === 0) && (
                        <div className="my-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button className="btn btn-secondary" onClick={() => handleQuitButtonClick()}>
                                Quit
                            </button>
                        </div>
                    )}
                </div>
                <animated.div style={{
                    position: 'fixed',
                    ...textAnimationStyle,
                    transform: textAnimationStyle.scale.to(scale => `scale(${scale})`)
                }}>{animationText}
                </animated.div>
            </div>
        </div>
    )
}

const generateOptions = (words: string[], startIndex: number): MemorizationGameOptionProps[] => {
    var count = Math.floor(Math.random() * 4) + 1
    if (count > words.length - startIndex){
        count = words.length - startIndex
    }
    var endIndex = startIndex + count;
    if (endIndex > words.length){
        endIndex = words.length
    }
    const correctOption: MemorizationGameOptionProps = {
        isCorrectOption: true,
        value: words.slice(startIndex, endIndex).join(" ")
    };
    var incorrectOptions = Array.from({length:3}, () => generateIncorrectOption(words, correctOption.value));
    var options = [correctOption, ...incorrectOptions];
    var shuffledOptions = shuffleArray(options);
    return shuffledOptions;
}

function generateIncorrectOption(words: string[], correctOption: string): MemorizationGameOptionProps {
    const randomRange = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

    const getRandomSubstringFromWords = (): string => {
        const length = randomRange(2, 4); // Length of the substring (2 to 4 words)
        const start = randomRange(0, words.length - length);
        return words.slice(start, start + length).join(' ');
    }

    let incorrectOption: MemorizationGameOptionProps = {
        isCorrectOption: false,
        value: getRandomSubstringFromWords()
    }
    
    let attempts = 0;
    while (attempts <= 10 && (incorrectOption.value === correctOption || incorrectOption.value.startsWith(correctOption) || correctOption.startsWith(incorrectOption.value))) {
        incorrectOption.value = getRandomSubstringFromWords();
        attempts++;
    }

    if (incorrectOption.value === correctOption) {
        incorrectOption.value = "freebie"
    }

    return incorrectOption;
}

const shuffleArray = (array: any[]) => {
    const arr = [...array];
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
};

type MemorizationGameOptionProps = {
    isCorrectOption: boolean;
    value: string;
}

export default MemorizationGame;