import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function NavBar() {
  var navbar = (
    <Navbar expand="lg">
      <Navbar.Brand className="p-2 fs-2">
        <Link to="/" className="logo">Adventure Crew</Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="pl-2 ml-auto">
          {/* <Nav.Link href="#link">This week</Nav.Link>
          <Nav.Link href="#link">Updates</Nav.Link>
          <Nav.Link href="#link">Schedule</Nav.Link> */}
            <NavDropdown.Divider />
          <NavDropdown title="Browse" id="basic-nav-dropdown">
            <NavDropdown.Item href="/books/1"><Link to="/books/1" className="dropdown-item2">Book One - Preparing for the Journey</Link></NavDropdown.Item>
            <NavDropdown.Item href="/books/2"><Link to="/books/2" className="dropdown-item2">Book Two - Lookout Mountain</Link></NavDropdown.Item>
            <NavDropdown.Item href="/books/3"><Link to="/books/3" className="dropdown-item2">Book Three - The Summit</Link></NavDropdown.Item>
            <NavDropdown.Item href="/books/4"><Link to="/books/4" className="dropdown-item2">Book Four - The Path</Link></NavDropdown.Item>
            <NavDropdown.Item href="/books/5"><Link to="/books/5" className="dropdown-item2">Book Five - Nature Center</Link></NavDropdown.Item>
            <NavDropdown.Item href="/books/6"><Link to="/books/6" className="dropdown-item2">Book Six - Campground</Link></NavDropdown.Item>
            <NavDropdown.Item href="/books/7"><Link to="/books/7" className="dropdown-item2">Book Seven - Lost in the Woods</Link></NavDropdown.Item>
            <NavDropdown.Item href="/books/8"><Link to="/books/8" className="dropdown-item2">Book Eight - Grow</Link></NavDropdown.Item>
            <NavDropdown.Item href="/books/9"><Link to="/books/9" className="dropdown-item2">Book Nine - Ranger Training</Link></NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );

  return (
    <div className='pb-3 mb-3'>
      {navbar}
    </div>
  );
}

export default NavBar;