import React, { useState, useEffect, createContext, useContext } from 'react';
import NavBar from './common/Navbar';
import BookLayout from './book/BookLayout'
import MileMarkerLayout from './book/MileMarkerLayout'
import Admin from './book/Admin'
import './App.css';
import "bootswatch/dist/zephyr/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import HomePage from './home/HomePage';
import initialState from './state.json';
import { State } from './types/State';
import DatePicker from './pages/MyDatePicker';

//mockup of an example page.
//hosting, completeness, two lambdas - one to get the current mile marker by date, one to get the current mile marker by book/marker number
//Install sass to easily overwrite bootswatch theme.


const AppStateContext = createContext<{
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
}>({
  state: initialState,
  setState: () => { }, // Default no-op function for setState
});


export const useAppState = () => useContext(AppStateContext);

function App() {


  const [state, setState] = useState(initialState);

  // replace initial state with authoritative state from database
  // useEffect(() => {
  //   fetch('/path/to/your/api')
  //     .then((response) => response.json())
  //     .then((data) => setState(data))
  //     .catch((error) => console.error(error));
  // }, []);

  useEffect(() => {
    document.title = "Adventure Crew"
  }, []);

  return (
    <AppStateContext.Provider value={{ state, setState }}>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/books/:id" element={<BookLayout />} />
          <Route path="/books/:bookId/milemarkers/:bookMileMarkerId" element={<MileMarkerLayout />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/datepicker"element={<DatePicker />} />
        </Routes>
      </Router>
    </AppStateContext.Provider>
  );
}

export default App;
