import React, { useEffect, useState } from 'react';
import BookMileMarker from './BookMileMarker';
import { Book } from '../types/Book'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useAppState } from '../App';

const MileMarkerLayout = () => {
    const { bookId, bookMileMarkerId } = useParams();
    const { state } = useAppState();

    const [book, setBook] = useState<Book>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.get<Book>(`https://api.buyck.dev/getBook?book=${bookId}`);
        
                const realBookId = parseInt(bookId || '0') - 1;
        
                setBook(state.books[realBookId]);
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        };

        fetchData();
    }, [bookId]);


    return (
        <div>
            <div className="row">
                <div className="col-sm-2">
                </div>
                <div className="col-sm-8">
                    <Breadcrumb className="breadcrumb2 px-4 p-md-0">
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href={`/books/${bookId}`}>Book {bookId}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Mile Marker {bookMileMarkerId}</Breadcrumb.Item>
                    </Breadcrumb>
                    {book && <BookMileMarker mileMarker={book.mileMarkers[Number(bookMileMarkerId) - 1]} index={0} />}
                </div>
                <div className="col-sm-2">
                </div>
            </div>
        </div>
    );
};

export default MileMarkerLayout;
